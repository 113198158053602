// Generated by Framer (d9af232)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Video } from "https://framerusercontent.com/modules/lRDHiNWNVWmE0lqtoVHP/TO50RZfUZkMws8Mz1efr/Video.js";
import * as localizedValues from "./Jc7ZGXtM8-0.js";
const VideoFonts = getFonts(Video);

const cycleOrder = ["hxUwkojFvNb1iFBFPz"];

const serializationHash = "framer-T4gXM"

const variantClassNames = {hxUwkojFvNb1iFBFPz: "framer-v-1l03dww"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {C3NO8bylo: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hxUwkojFvNb1iFBFPz", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1l03dww", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"hxUwkojFvNb1iFBFPz"} ref={ref ?? ref1} style={{...style}}><ComponentViewportProvider ><motion.div className={"framer-1ofwya2-container"} layoutDependency={layoutDependency} layoutId={"hxUwkojFvzHbu02PjN-container"}><Video backgroundColor={"rgba(173, 147, 147, 0)"} borderRadius={0} bottomLeftRadius={0} bottomRightRadius={0} controls={false} height={"100%"} id={"hxUwkojFvzHbu02PjN"} isMixedBorderRadius={false} layoutId={"hxUwkojFvzHbu02PjN"} loop muted objectFit={"cover"} playing posterEnabled={false} srcFile={"https://framerusercontent.com/assets/srLqgMueMZYOemEFkGH3SPFvipo.mp4"} srcType={"Upload"} srcUrl={getLocalizedValue("v0", activeLocale) ?? "https://assets.mixkit.co/videos/preview/mixkit-ice-cream-glass-of-red-soda-5094-small.mp4"} startTime={0} style={{height: "100%", width: "100%"}} topLeftRadius={0} topRightRadius={0} volume={25} width={"100%"}/></motion.div></ComponentViewportProvider><motion.div className={"framer-ofu3l0"} layoutDependency={layoutDependency} layoutId={"hxUwkojFvsPu9LTFat"} style={{background: "linear-gradient(180deg, rgba(84, 84, 84, 0.65) 64%, rgb(0, 0, 0) 100%)"}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-T4gXM.framer-zegdr3, .framer-T4gXM .framer-zegdr3 { display: block; }", ".framer-T4gXM.framer-1l03dww { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 137px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 244px; }", ".framer-T4gXM .framer-1ofwya2-container { aspect-ratio: 1.7862068965517242 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 139px); left: -2px; position: absolute; right: -2px; top: -1px; z-index: 1; }", ".framer-T4gXM .framer-ofu3l0 { aspect-ratio: 1.7946428571428572 / 1; flex: none; height: var(--framer-aspect-ratio-supported, 139px); left: -2px; overflow: hidden; position: absolute; right: -3px; top: 0px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-T4gXM.framer-1l03dww { gap: 0px; } .framer-T4gXM.framer-1l03dww > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-T4gXM.framer-1l03dww > :first-child { margin-left: 0px; } .framer-T4gXM.framer-1l03dww > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 137
 * @framerIntrinsicWidth 244
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJc7ZGXtM8: React.ComponentType<Props> = withCSS(Component, css, "framer-T4gXM") as typeof Component;
export default FramerJc7ZGXtM8;

FramerJc7ZGXtM8.displayName = "VideoImageHero";

FramerJc7ZGXtM8.defaultProps = {height: 137, width: 244};

addFonts(FramerJc7ZGXtM8, [{explicitInter: true, fonts: []}, ...VideoFonts], {supportsExplicitInterCodegen: true})